<template>
  <div class="bottom-bar">
    <div class="bottom-bar__progress">
      <v-progress-linear
        height="10"
        value="35"
        striped
      ></v-progress-linear>
    </div>
    <v-btn
      class="bottom-bar__btn btn">
      Скачать отчёты
    </v-btn>
    <v-btn
      class="bottom-bar__btn btn">
      Отправить отчёты
    </v-btn>
    <v-btn
      class="bottom-bar__btn btn">
      Отправить на 1 почту
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: "BottomBar"
  }
</script>

<style lang="scss">
  .bottom-bar {
    display: flex;
    grid-gap: 0 25px;
    position: fixed;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 40px;
    background: linear-gradient(178deg, #5c5c5c, #0000);
    box-shadow: 0 0 15px #323232;
    border-radius: 6px;
    border: 1px solid #5c5c5c;

    &__btn {
      background: $clr_one !important;
    }

    &__btn:nth-of-type(2) {
      background: $clr_two !important;
    }

    &__progress {
      position: absolute;
      width: 100%;
      left: 0;
      top: -15px;
    }

  }

  .v-progress-linear__determinate {
    animation: progress .42s linear infinite;
  }

  @keyframes progress {
    0% {
      background-position: 0 0px;
    }
    100% {
      background-position: 0 39px;
    }
  }
</style>
