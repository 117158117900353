<template>
  <div
    class="sidebar"
    :class="{
      'open': sidebar_status
    }"
  >
    <DomainSidebar
      v-show="sidebar_type === 'domain'"
      :domain_info='domain_info'
      :managers="managers"
    />
    <ManagersSidebar
      v-show="sidebar_type === 'managers'"
      :managers="managers"
    />
    <div class="sidebar__buttons">
      <v-btn
        v-show="!(sidebar_type === 'managers')"
        @click="$emit('change_domain_info')"
      >
        Сохранить
      </v-btn>
      <v-btn
        @click="$emit('toggle_sidebar', {
          open: false
        })"
      >
        Отмена
      </v-btn>
    </div>
  </div>
</template>

<script>
  import DomainSidebar from './DomainSidebar';
  import ManagersSidebar from './ManagersSidebar';
  export default {
    name: 'Sidebar',
    components: {ManagersSidebar, DomainSidebar},
    /* eslint-disable */
    props: {
      domain_info: {
        required: true,
        default: {}
      },
      managers: {
        type: Array,
        required: false
      },
      sidebar_status: {
        type: Boolean,
        required: true,
        default: false
      },
      sidebar_type: {
        type: String,
        required: true,
        default: ''
      },
    },
    methods: {},
    watch: {
      show_sidebar: function() {
        this.show = this.show_sidebar;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 345px;
    padding: 35px 15px 20px;
    background: #1b1b1b;
    overflow: auto;

    transform: translateX(-100%);
    opacity: 0;
    transition: .2s ease-in-out;
    z-index: 11;

    &.open {
      transform: translateX(0);
      opacity: 1;
    }


    &__buttons {
      display: flex;
      justify-content: space-around;

      margin-top: 45px;

      button:first-child {
        background-color: $clr_two !important;
      }

      button:nth-child(2) {
        background-color: $clr_three !important;
      }

    }
  }
</style>
