/* eslint-disable */
import Chart from '../vendors/chart';
import ChartDataLabels from '../vendors/chartjs-plugin-datalabels';
import alert_message from '../../resources/js/alert_message';

export default response => {
  let frame = document.getElementsByTagName('iframe')[0];
  const response_format = {
    total_avg_time: response.yandexMetric.totalAvgTime ?? '',
    domain_name: response.domain_name ?? '',
    conversion: response.conversion ?? '',
    adv_ticket_percent: response.proportiontOfTicketsAdv ?? '',
    date_to: response.dateToFormatted ?? '',
    date_from: response.dateFromFormatted ?? '',
    date_to_month: response.dateToMonthFormatted ?? '',
    phone_image: response.image ?? './img/mazda.jpg',
    emails: response.emails ?? [],
  };

  frame.contentWindow.document.getElementById('avg_time_on_site').innerHTML = response_format.total_avg_time;
  frame.contentWindow.document.getElementById('title').innerHTML = `«${response.domain_name}»`;
  frame.contentWindow.document.getElementById('conversion').innerHTML = response_format.conversion;
  frame.contentWindow.document.getElementById('adv_ticket_percent').innerHTML = response_format.adv_ticket_percent;
  frame.contentWindow.document.getElementById('date').innerHTML =
    response_format.date_from + ' - ' + response_format.date_to;
  frame.contentWindow.document.getElementById('date2').innerHTML = response_format.date_to_month;
  frame.contentWindow.document.getElementById('imageFirst').style.backgroundImage =
    'url(' + `${response_format.phone_image}` + ')';

  renderChartVisitors(response);
  renderChartTotalRequests(response);
  if (response['tickets']['requests_sorted_by_caption'])
    renderChartLastMonthRequests(response['tickets']['requests_sorted_by_caption']);

  const graph1 = document.getElementById('chart1');
  const graph2 = document.getElementById('chart2');
  const graph3 = document.getElementById('chart3');

  frame.contentWindow.document.getElementById('chart1').src = graph1.toDataURL();
  frame.contentWindow.document.getElementById('chart2').src = graph2.toDataURL();
  frame.contentWindow.document.getElementById('chart3').src = graph3.toDataURL();

  const html = frame.contentWindow.document.body.innerHTML;
  let element = document.createElement('div');
  element.classList.add('js-graph-box');

  element.innerHTML = html;

  // ------------------------------------------ отрисовка графиков ---------------------------------------------
  function renderChartVisitors(data) {
    let labels = Object.keys(data['yandexMetric']['statisticEveryMonth']);
    let datasets = [];

    let social = [];
    let referral = [];
    let internal = [];
    let organic = [];
    let direct = [];
    let ad = [];
    let other = [];

    for (let month in data['yandexMetric']['statisticEveryMonth']) {
      social.push(data['yandexMetric']['statisticEveryMonth'][month]['social']);
      referral.push(data['yandexMetric']['statisticEveryMonth'][month]['referral']);
      internal.push(data['yandexMetric']['statisticEveryMonth'][month]['internal']);
      organic.push(data['yandexMetric']['statisticEveryMonth'][month]['organic']);
      direct.push(data['yandexMetric']['statisticEveryMonth'][month]['direct']);
      ad.push(data['yandexMetric']['statisticEveryMonth'][month]['ad']);
      other.push(data['yandexMetric']['statisticEveryMonth'][month]['other']);
    }

    datasets.push(
      {
        label: 'Переходы из соц.сетей',
        backgroundColor: '#3C7AC5',
        data: social,
      },
      {
        label: 'Переходы по рекламе',
        backgroundColor: '#C13A36',
        data: ad,
      },
      {
        label: 'Прямые заходы',
        backgroundColor: '#FA8D28',
        data: direct,
      },
      {
        label: 'Переходы по ссылкам на сайтах',
        backgroundColor: '#7957A4',
        data: referral,
      },
      {
        label: 'Внутренние переходы',
        backgroundColor: '#36B1D2',
        data: internal,
      },
      {
        label: 'Переходы из поисковых систем',
        backgroundColor: '#95BC45',
        data: organic,
      },
      {
        label: 'Остальное',
        backgroundColor: '#1E487A',
        data: other,
      }
    );

    /*reverse labels*/
    //                    data.isMixedYears ? labels.reverse() : labels

    let ctx = document.getElementById('chart1').getContext('2d');
    let chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      plugins: [ChartDataLabels],
      options: {
        layout: {
          padding: {
            top: 40,
          },
        },
        animation: false,
        plugins: {
          barWidth: 100,
          datalabels: {
            color: '#1f1f1f',
            align: 'end',
            anchor: 'end',
            clamp: false,
            font: {
              size: 18,
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'rgb(160, 160, 160)',
            fontSize: 20,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: true,
                fontSize: 22,
              },
              gridLines: {
                display: false,
                fontSize: 22,
              },
              maxBarThickness: 40,
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
                fontSize: 20,
              },
              gridLines: {
                display: false,
                fontSize: 20,
              },
              // stacked: true, так выглядит совсем убого
            },
          ],
        },
      },
    });
    return chart;
  }

  function renderChartTotalRequests(data) {
    console.log(data)
    let datasets = [];
    let labels = Object.keys(data['yandexMetric']['statisticEveryMonth']);
    let arrayObject = data['tickets']['requests_sorted_by_month'] ? Object.values(data['tickets']['requests_sorted_by_month']) : [];

    for (let i = 0; i < arrayObject.length; i++) {
      for (let key in arrayObject[i]) {
        datasets.push(arrayObject[i][key]);
      }
    }

    //                    for (let key in data['requests_sorted_by_month']) {
    //                        datasets.push(data['requests_sorted_by_month'][key]);
    //                    }

    let ctx = document.getElementById('chart2').getContext('2d');
    let chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Заявки',
            data: datasets,
            backgroundColor: '#36A2EB',
            font: {
              size: 28,
            },
            maxBarThickness: 100,
          },
        ],
      },
      plugins: [ChartDataLabels],
      barThickness: 1,
      options: {
        plugins: {
          barWidth: 100,
          datalabels: {
            color: '#ffffff',
            align: 'center',
            clamp: true,
            textAlign: 'center',
            font: {
              size: 24,
            },
          },
        },
        animation: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'rgb(160, 160, 160)',
            fontSize: 24,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: true,
                fontSize: 24,
              },
              gridLines: {
                display: false,
                fontSize: 18,
              },
              stacked: true,
              barThickness: 200,
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
                fontSize: 22,
              },
              gridLines: {
                display: true,
                fontSize: 22,
              },
              stacked: true,
            },
          ],
        },
      },
    });

    return chart;
  }

  function renderChartLastMonthRequests(data) {
    let month = Object.keys(data)[0];
    let labels = [];
    let datasets = [];

    //                    for (let key in data[month]) {
    //                        labels.push(key);
    //                        datasets.push(data[month][key]);
    //                    }

    let sortable = [];
    for (let item in data[month]) {
      sortable.push([item, data[month][item]]);
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });

    for (let i = 0; i < sortable.length; i++) {
      labels.push(sortable[i][0]);
      datasets.push(sortable[i][1]);
    }

    let ctx = document.getElementById('chart3').getContext('2d');
    let chart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Заявки',
            data: datasets,
            backgroundColor: '#36A2EB',
            borderColor: '#ffffff',
            borderWidth: 2,
            font: {
              size: 18,
            },
            maxBarThickness: 55,
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          datalabels: {
            propagate: true,
            color: '#ffffff',
            font: {
              size: 18,
            },
            align: 'end',
          },
        },

        animation: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'rgb(160, 160, 160)',
            fontSize: 18,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
                fontSize: 24,
              },
              gridLines: {
                display: false,
                fontSize: 24,
              },
              stacked: true,
              barThickness: 65,
            },
          ],
          yAxes: [
            {
              ticks: {
                display: true,
                fontSize: 18,
              },
              gridLines: {
                display: true,
                fontSize: 30,
              },
              stacked: true,
              barThickness: 65,
            },
          ],
        },
      },
    });

    return chart;
  }

  if (response.move_type === 'save') {
    html2pdf(element, {
      margin: [0, 0, 0, 0],
      filename: 'Отчет по ДЦ "' + `${response_format.domain_name ?? 'unnamed'}` + '".pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2, dpi: 192 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    });
  } else if ((response.move_type = 'send')) {
    let opt = {
      margin: [0, 0, 0, 0],
      filename: 'Отчет по ДЦ "' + `${response_format.domain_name ?? 'unnamed'}` + '".pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2, dpi: 192 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .output('datauristring')
      .then(function (pdfAsString) {
        let data = {
          emails: response_format.emails,
          pdftobase64: pdfAsString,
        };
        $.ajax({
          url: 'api/api/send_pdf_email',
          type: 'post',
          data,
          dataType: 'json',
        })
          .done(function (response) {
            console.log('zaebis');
          })
          .catch(e => {
            console.log(e);
          });
      });
  } else {
    console.log('Неопознанный тип действия');
  }
};
