export default () => {
  const scroll_btn = document.querySelector('.js-scroll-top');

  document.onscroll = () => {
    window.scrollY > 300 ? (scroll_btn.style.display = 'block') : (scroll_btn.style.display = 'none');
  };

  scroll_btn.onclick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
};
