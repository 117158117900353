var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"managers"},[_vm._l((_vm.managers),function(manager){return _c('div',{key:manager.id,staticClass:"managers__element",attrs:{"data-id":"manager.id"}},[_vm._v(" "+_vm._s(manager.name)+" "),_c('span',{staticClass:"managers__element-id"},[_vm._v(" id: "+_vm._s(manager.id)+" ")]),_c('v-btn',{staticClass:"managers__element-del",attrs:{"x-small":""},on:{"click":function($event){return _vm.$parent.$emit('dell_manager', {
        id: manager.id,
        name: manager.name
      })}}},[_vm._v(" удалить ")])],1)}),_c('v-text-field',{staticClass:"managers__add-manager",attrs:{"label":"Новый менеджер"},on:{"focusout":function($event){return _vm.$parent.$emit('new_manager_edit', {
      new_manager: _vm.new_manager
    })}},model:{value:(_vm.new_manager),callback:function ($$v) {_vm.new_manager=$$v},expression:"new_manager"}}),_c('v-btn',{on:{"click":function () {
      _vm.$parent.$emit('add_new_manager');
      this$1.new_manager = ''
    }}},[_vm._v(" Добавить менеджера ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }