<template>
  <div class="sortable">
    <div class="sortable__element">
      <v-checkbox
        v-model="select_all"
        @change="$emit('checked_all', select_all)"
      />
    </div>
    <div class="sortable__element sortable__element--sorting"
         @click="$emit('sortable', 'dealer_name')">
      <v-btn
        small
        class="sortable__element-name">
        Название ДЦ
        <span class="arrow"
              v-show="sortable_status.dealer_name"
              :class="{
                'asc': sortable_status.dealer_name === 'ASC'
              }"
        ></span>
      </v-btn>
    </div>
    <div class="sortable__element sortable__element--sorting">
      <v-btn
        small
        class="sortable__element-name"
        @click="change_status">
        Статус
        <span class="filter"
              v-show="status"
              :class="{
                'active': status === 'Активен'
              }"
        >{{ status[0] }}</span>
      </v-btn>
    </div>
    <div class="sortable__element">
      <span class="sortable__element-name">
        Витрина
      </span>
    </div>
    <div class="sortable__element">
      <span class="sortable__element-name">
        ID метрики
      </span>
    </div>
    <div class="sortable__element">
      <span class="sortable__element-name">
        Менеджер
      </span>
    </div>
    <div class="sortable__element">
      Действия
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Sortable',
    props: {
      sortable_status: {
        type: Object
      },
    },
    data() {
      return {
        select_all: false,
        status: false
      };
    },
    methods: {
      change_status() {
        if (this.status) {
          this.status === 'Активен' ?
            this.status = false :
            this.status = 'Активен';
        } else this.status = 'Отключен';
        if (this.status) {
          this.$emit('filter', {
            status: [this.status]
          });
        } else {
          this.$emit('filter', {
            status: ''
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .sortable {
    position: relative;
    display: grid;
    grid-template-columns: 0.2fr 1.2fr 0.7fr 1.2fr 1fr 1fr 150px;
    align-items: center;

    max-width: 1150px;
    margin: 45px auto 25px;
    padding: 5px 25px;

    background: linear-gradient( 179deg, #2c2c2c, #0d0d0d);
    border: 1px solid #dddddd36;
    border-radius: 4px;
    user-select: none;

    z-index: 3;

    &__element {
      position: relative;
      cursor: pointer;

      & .filter {
        position: absolute;
        font-size: 11px;
        top: -2px;
        right: -45px;
        color: #ececec;
        background: linear-gradient(
            181deg
          , $clr_three, #0000);
        padding: 3px 10px;
        border-radius: 10px;

        transition: .13s ease-in;

        &.active {
          background: linear-gradient(
              181deg
            , $clr_two, #0000) !important;
        }
      }

      & .arrow {
        position: relative;

        &::before,
        &::after {
          display: none;
          position: absolute;
          content: '';
          left: 10px;
          top: 50%;
          height: 3px;
          width: 12px;

          background: $clr_three;
          border-radius: 5px;
          transition: .1s ease-in;
        }

        &::after {
          transform: translateY(-50%) rotate(-45deg);
        }
        &::before {
          transform: translate(8px, -50%) rotate(45deg);
        }

        &.asc {
          &::after {
            transform: translateY(-50%) rotate(-135deg);
            background: $clr_two;
          }
          &::before {
            transform: translate(8px, -50%) rotate(135deg);
            background: $clr_two;
          }
        }
      }

      &--sorting {
        cursor: pointer;
        & .sortable__element-name {
          background: linear-gradient(
              45deg
            , #404040, #0000);
          padding: 5px;
          border: 1px solid #605f5f;
          border-radius: 4px;
          box-shadow: -3px -3px 6px #1c1c1c9c,
                      3px 3px 6px #dddddd14;
        }

        & .arrow {
          &::before,
          &::after {
            display: block;
          }
        }
      }
    }
  }
</style>
