<template>
  <div class="profile">
    <h3 class="profile__title">
      Редактировать запись
    </h3>
    <div class="profile__field">
      <v-text-field
        label="Название ДЦ"
        :value="domain_info.name"
        v-model="domain_info.name"
      />
    </div>
    <div class="profile__field">
      <v-select
        v-model="domain_info.status"
        dark
        label="Статус"
        :items="['Активен', 'Отключен']"
        :value="domain_info.status"
      />
    </div>
    <div class="profile__field">
      <v-text-field
        label="Домен"
        :value="domain_info.domain"
        disabled
      />
    </div>
    <div class="profile__field">
      <v-text-field
        label="ID метрики"
        v-model="domain_info.metrika"
        :value="domain_info.metrika"
      />
    </div>
    <div class="profile__field">
      <v-select
        dark
        :value="select"
        v-model="select"
        label="Менеджер"
        :items="managers"
        item-text="name"
        item-value="id"
        persistent-hint
        return-object
        single-line
        @change="$parent.$emit('change_domain_manager', select.id)"
      />
    </div>

    <div class="profile__emails">
      <div class="profile__emails-ttl">
        Список почт:
      </div>
      <v-chip
        v-for="email in emails"
        :key="email"
      >
        {{ email }}
        <v-btn
          x-small
          class="profile__emails-del"
          @click="drop_email(email)"
        >del
        </v-btn>
      </v-chip>
      <v-text-field
        label="Новая почта"
        v-model="new_email"
      />
      <v-btn
        small
        @click="push_email"
      >Добавить почту</v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DealerProfile',
    props: {
      domain_info: {
        type: Object,
        required: true
      },
      managers: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        select: this.current_manager(this.managers),
        new_email: '',
        emails: this.domain_info.emails
      };
    },
    watch: {
      domain_info: {
        handler() {
          this.select = this.current_manager(this.managers);
          this.emails = this.domain_info.emails;
        },
        deep: true
      },
    },
    methods: {
      current_manager(managers) {
        let result;
        managers.forEach(m => {
          if (m.id === +this.domain_info.accountable_id)
            result = {id: m.id, name: m.name};
        });
        return result;
      },
      push_email() {
        this.emails.push(this.new_email);
        this.new_email = '';
        this.$parent.$emit('push_emails', this.emails);
      },
      drop_email(mail) {
        this.emails = this.emails.filter(e => e !== mail);
      }
    },
  };
</script>

<style lang="scss">
  .profile {
    display: flex;
    flex-direction: column;
    grid-gap: 35px 0;

    &__field {
      padding: 0 10px;
      box-shadow: -4px 4px 8px #171717, 4px -4px 8px #1d1d1d;
      border-radius: 6px;
      border: 1px solid #313030;
    }

    &__emails {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid #414040;
      padding: 20px 10px;
      border-radius: 4px;
      .v-input {margin-bottom: 20px;}

      .v-btn {
        width: 100%;
      }
    }

    &__emails-ttl {
      width: 100%;
      margin-bottom: 10px;
    }

    &__emails-del {
      margin-left: 10px;
    }

    i {
      top: -10px;
    }
    .v-select__selection--comma {
      margin: 7px 4px 0 0 !important;
    }

    .v-input input {
      padding: 14px 0 0 !important;
    }

    .v-chip {
      border: 1px solid #757575 !important;
      margin-bottom: 5px !important;
      margin-right: 5px !important;

      .v-btn {
        width: auto;
      }
    }

    .v-chip__content {
      width: 100% !important;
    }
  }
</style>
