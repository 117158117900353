<template>
  <div>
    <span id="pdf-box"></span>
  </div>
</template>

<script>


  export default {
    name: 'Pdf',
    data() {
      return {
        // response: {
        //   "tickets":{
        //     "requests_sorted_by_month":{
        //       "2021":{
        //         "07":1,
        //         "09":1
        //       }
        //     },
        //     "requests_sorted_by_caption":{
        //       "июль":{
        //         "Заказ консультации":2,
        //         "Cegth sdfsdf":10,
        //         "Заявки просто так":14,
        //         "Заявки не просто так...":5,
        //       }
        //     }
        //   },
        //   "yandexMetric":{
        //     "statisticEveryMonth":{
        //       "август":{
        //         "other":0,
        //         "total":69,
        //         "avg_time":3332.4852813899997,
        //         "organic":43,
        //         "direct":19,
        //         "internal":4,
        //         "referral":2,
        //         "ad":1
        //       },
        //       "сентябрь":{
        //         "other":0,
        //         "total":3,
        //         "avg_time":461,
        //         "direct":2,
        //         "organic":1
        //       },
        //       "Хуябрь":{
        //         "other":14,
        //         "total":3,
        //         "avg_time":461,
        //         "direct":43,
        //         "organic":22
        //       }
        //     },
        //     "totalVisitorsCount":146,
        //     "totalAvgTime":"108.59"
        //   },
        //   "countTickects":2,
        //   "websiteName":"Каскад-Урал",
        //   "conversion":33.33333333333333,
        //   "proportiontOfTicketsAdv":0,
        //   "dateFromFormatted":"июль 2021",
        //   "dateToFormatted":"сентябрь 2021",
        //   "dateToMonthFormatted":"сентябрь",
        //   "image":"img/audi.jpg"
        // }
      }
    },
    methods: {
      generateReport() {
        pdf_created(this.response);
      }
    },
    watch: {
      response: function() {
        this.generateReport(this.response)
      }
    }
  };
</script>

<style scoped>

</style>
