<template>
  <div class="list">
    <Preloader
      class="wrapper__preloader"
      :class="{
          'show': preloader_status
        }"
    />
    <template v-show="!preloader_status">
      <ListElement
        v-for="(site,id) in domains"
        :key="id"
        :site="site"
      />
    </template>
  </div>
</template>

<script>
  import ListElement from './ListElement';
  import Preloader from './Preloader';

  export default {
    name: 'List',
    components: {ListElement, Preloader},
    props: {
      domains: {
        required: false,
        type: Array,
        default: () => {
        }
      },
      preloader_status: {
        required: true,
        type: Boolean
      }
    },
  };
</script>

<style lang="scss">
  .list {
    position: relative;
    z-index: 2;
  }
</style>
