import Vue from 'vue';
import Index from './Index.vue';
import vuetify from './plugins/vuetify';
import '@fortawesome/fontawesome-free/css/all.css';
import Vuetify from 'vuetify';

Vue.config.productionTip = true;

new Vue({
  vuetify,
  render: h => h(Index),
}).$mount('#index');

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      download: 'fas fa-download',
    },
  },
});
