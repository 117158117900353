var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"header__selects"},[_c('div',{staticClass:"header__select-brand"},[_c('v-select',{attrs:{"dark":"","multiple":"","outlined":"","label":"Бренды","items":_vm.brands,"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.$emit('change_filter', {
            brand_name: _vm.selected_brands
          })}},model:{value:(_vm.selected_brands),callback:function ($$v) {_vm.selected_brands=$$v},expression:"selected_brands"}})],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],staticClass:"header__date-pick"},[_c('v-btn',{staticClass:"header__date-toggle",attrs:{"dark":""},on:{"click":function($event){_vm.toggle_date_picker = !_vm.toggle_date_picker}}},[_vm._v(" "+_vm._s(_vm.getFormat(_vm.dates))+" ")]),_c('v-date-picker',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggle_date_picker),expression:"toggle_date_picker"}],staticClass:"header__date",attrs:{"dark":"","range":""},on:{"change":function($event){return _vm.$emit('change_date', _vm.dates)}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('div',{staticClass:"header__select-people"},[_c('v-select',{attrs:{"dark":"","multiple":"","outlined":"","label":"Менеджеры","items":_vm.managers,"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.$emit('change_filter', {
            accountable_id: _vm.selected_people
          })}},model:{value:(_vm.selected_people),callback:function ($$v) {_vm.selected_people=$$v},expression:"selected_people"}})],1),_c('v-btn',{staticClass:"header__managers-list",attrs:{"x-small":""},on:{"click":function($event){return _vm.$emit('toggle_sidebar', {
          open: true,
          sidebar_type: 'managers'
        })}}},[_vm._v(" Список менеджеров ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header__logotype"},[_c('div',{staticClass:"header__self-logo"},[_vm._v(" Reporting "),_c('span',[_vm._v("service")])])])}]

export { render, staticRenderFns }