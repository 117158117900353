<template>
  <div
    class="list-element"
    :class="{
      'checked': site.checked,
    }"
  >
    <div class="list-element__dealer-name">
      <v-checkbox
        v-model="site.checked"
        :label="site.name + ' (' + site.id + ')'"
      />
    </div>
    <div class="list-element__status">
      {{ site.status ? site.status : 'не указан' }}
    </div>
    <div class="list-element__site">
      {{ site.domain ? site.domain : 'не указан' }}
    </div>
    <div class="list-element__id">
      {{ site.metrika ? site.metrika : 'не указан' }}
    </div>
    <div class="list-element__manager">
      {{ site.accountable_name ? site.accountable_name : 'не указан' }}
    </div>
    <div class="list-element__buttons">
      <v-btn
        small
        @click="$parent.$emit('get_report_data', {
          domain: site.domain ? site.domain : '',
          id: site.metrika ? site.metrika : '',
          domain_name: site.name ? site.name : '',
          emails: site.emails ? site.emails : '',
          move_type: 'save'
        })"
      >
        <v-icon>fas fa-download</v-icon>
      </v-btn>
      <v-btn
        v-if="false"
        small
        @click="$parent.$emit('get_report_data', {
          domain: site.domain ? site.domain : '',
          id: site.metrika ? site.metrika : '',
          domain_name: site.name ? site.name : '',
          emails: site.emails ? site.emails : '',
          move_type: 'send'
        })"
      >
        <v-icon>fas fa-at</v-icon>
      </v-btn>
      <v-btn
        small
        @click="$parent.$emit('toggle_sidebar', {
          domain: site.domain,
          open: true,
          sidebar_type: 'domain'
        })"
      >
        <v-icon>fas fa-pen</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ListElement',
    props: {
      site: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        checked: this.get_select_status(),
      };
    },
    watch: {
      site: function() {
        this.checked = this.get_select_status();
      }
    },
    methods: {
      get_select_status() {
        return this.site.checked;
      },
    },
  };
</script>

<style lang="scss">
  .list-element {
    display: grid;
    grid-template-columns: 1.5fr .5fr 1.5fr 1fr 1fr 150px;
    grid-gap: 0 10px;
    align-items: center;

    max-width: 1150px;
    margin: 0 auto;
    padding: 5px 25px;

    border-bottom: 1px solid #dddddd38;
    transition: 0.2s ease-in-out;

    &__site {
      text-align: center;
    }

    &:nth-child(even) {
      background: #222222;
    }

    &:nth-child(odd) {
      background: #4c4c4c;
    }

    &.checked {
      box-shadow: 2px 2px 4px #000000, inset 0 0 10px #00f870;
    }

    &__buttons {
      display: grid;
      grid-gap: 0 5px;
      grid-template-columns: 1fr 1fr 1fr;

      button:nth-child(1) {
        background: $clr_one !important;
      }

      button:nth-child(2) {
        background: $clr_two !important;
      }

      button:nth-child(3) {
        background: $clr_three !important;
      }
    }

    .theme--dark.v-label {
      color: #ececec !important;
    }

    .v-icon {
      font-size: 16px;
    }

    .v-input {
      .v-icon {
        font-size: 24px;
      }
    }
  }
</style>
