var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-element",class:{
    'checked': _vm.site.checked,
  }},[_c('div',{staticClass:"list-element__dealer-name"},[_c('v-checkbox',{attrs:{"label":_vm.site.name + ' (' + _vm.site.id + ')'},model:{value:(_vm.site.checked),callback:function ($$v) {_vm.$set(_vm.site, "checked", $$v)},expression:"site.checked"}})],1),_c('div',{staticClass:"list-element__status"},[_vm._v(" "+_vm._s(_vm.site.status ? _vm.site.status : 'не указан')+" ")]),_c('div',{staticClass:"list-element__site"},[_vm._v(" "+_vm._s(_vm.site.domain ? _vm.site.domain : 'не указан')+" ")]),_c('div',{staticClass:"list-element__id"},[_vm._v(" "+_vm._s(_vm.site.metrika ? _vm.site.metrika : 'не указан')+" ")]),_c('div',{staticClass:"list-element__manager"},[_vm._v(" "+_vm._s(_vm.site.accountable_name ? _vm.site.accountable_name : 'не указан')+" ")]),_c('div',{staticClass:"list-element__buttons"},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.$parent.$emit('get_report_data', {
        domain: _vm.site.domain ? _vm.site.domain : '',
        id: _vm.site.metrika ? _vm.site.metrika : '',
        domain_name: _vm.site.name ? _vm.site.name : '',
        emails: _vm.site.emails ? _vm.site.emails : '',
        move_type: 'save'
      })}}},[_c('v-icon',[_vm._v("fas fa-download")])],1),(false)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.$parent.$emit('get_report_data', {
        domain: _vm.site.domain ? _vm.site.domain : '',
        id: _vm.site.metrika ? _vm.site.metrika : '',
        domain_name: _vm.site.name ? _vm.site.name : '',
        emails: _vm.site.emails ? _vm.site.emails : '',
        move_type: 'send'
      })}}},[_c('v-icon',[_vm._v("fas fa-at")])],1):_vm._e(),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.$parent.$emit('toggle_sidebar', {
        domain: _vm.site.domain,
        open: true,
        sidebar_type: 'domain'
      })}}},[_c('v-icon',[_vm._v("fas fa-pen")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }