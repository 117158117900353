<template>
  <div class="notification"
       :class="{
          'show': show
       }"
  >
    <h3 class="notification__title">{{ message.title }}</h3>
    <div class="notification__message">{{ message.text }}</div>
  </div>
</template>

<script>
  export default {
    name: 'Notification',
    props: {
      message: {
        type: Object,
        required: true
      },
      show: {
        type: Boolean,
        required: true,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .notification {
    opacity: 0;
    position: fixed;
    left: 355px;
    top: 10px;
    min-width: 265px;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 8px;
    background: linear-gradient(45deg, #5e5e5e, #2c2c2c);
    box-shadow: 0 0 10px #5b5b5b;
    font-size: 14px;
    z-index: 12;
    transform: scaleY(0.785);
    transition: .15s ease-in-out;

      &.show {
        opacity: 1;
        transform: scaleY(1);
      }

  }
</style>
