<template>
  <v-app id="index"
         class="reports"
  >
    <v-container class="wrapper"
                 @click="show_notification = false"
    >
      <v-progress-linear
        class="reports__progress-bar"
        :class="{
          'show': progress_bar_on
        }"
        color="blue accent-2"
        indeterminate
        rounded
      />
      <Notification
        :message="notification_message"
        :show="show_notification"
      />
      <Header
        :brands="brands"
        :managers="managers"
        @get_managers="get_managers"
        @toggle_sidebar="sidebar_toggle"
        @change_date="changes_date"
        @change_filter="change_filter"
      />
      <Sortable
        :sortable_status="sorting_data"
        @checked_all="check_all"
        @sortable="filter_sortable"
        @filter="change_filter"
      />
      <List
        class="reports__list"
        :class="{
          'preloader_on': preloader_status
        }"
        :domains="domains"
        :preloader_status="preloader_status"
        @toggle_sidebar="sidebar_toggle"
        @get_report_data="get_report_data"
      />
      <Sidebar
        :domain_info="domain_info"
        :managers="managers"
        :sidebar_status="sidebar_status"
        :sidebar_type="sidebar_type"
        :new_manager="new_manager"
        @toggle_sidebar="sidebar_toggle"
        @change_domain_info="change_domain_info"
        @change_sidebar_manager="change_sidebar_manager"
        @new_manager_edit="new_manager_edit"
        @add_new_manager="add_new_manager"
        @dell_manager="dell_manager"
        @change_domain_manager="change_domain_manager"
        @push_emails="update_emails_sidebar"
      />
      <v-btn type="button"
             id="scroll-top-btn"
             class="btn js-scroll-top waves-teal">
        <span class="arrow_up">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-up"
            class="svg-inline--fa fa-arrow-up fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512">
            <path
              fill="currentColor"
              d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z">
            </path>
          </svg>
        </span>
      </v-btn>
      <BottomBar />
      <Pdf :response="domain_statistic"></Pdf>
    </v-container>
  </v-app>
</template>

<script>
  import Header from './components/Header';
  import List from './components/List';
  import Sortable from './components/Sortable';
  import axios from 'axios';
  import Sidebar from './components/Sidebar';
  import Preloader from './components/Preloader';
  import Notification from './components/Notification';
  import Pdf from './components/Pdf';
  import scroll_top from './plugins/scroll_top';
  import pdf_created from './plugins/pdf_created';
  import BottomBar from "./components/BottomBar";

  export default {
    name: 'Index',
    components: {
      Notification,
      Preloader,
      Sidebar,
      Header,
      List,
      Sortable,
      Pdf,
      BottomBar
    },
    data() {
      return {
        brands: [],
        managers: [],
        new_manager: '',
        domains: [
          {
            name: '',
            status: '',
            domain: '',
            metrika: '',
            accountable_id: '',
            accountable_name: '',
            checked: false
          },
        ],
        domain_info: {
          type: 'profile',
          name: 'name',
          domain: 'domain',
          status: 'status',
          metrika: 'metrika',
          accountable_id: '1',
          accountable_name: 'manager',
          emails: []
        },
        sidebar_status: false,
        preloader_status: false,
        sidebar_type: 'domain',
        request_url: '/api/api',
        notification_message: {
          title: 'Alarm!',
          text: 'Тестовое уведомление, что бы протестить увеомления'
        },
        show_notification: false,
        report_data: {
          domain: '',
          date: '',
          counter_id: ''
        },
        filters_data: {},
        sorting_data: {
          dealer_name: false
        },
        domain_statistic: {},
        progress_bar_on: false,
        auth_token: `Bearer ${localStorage.tokenData.replace(/["]+/g, '')}`
      };
    },
    mounted: function() {
      this.get_domains_list();
      this.get_managers();
      scroll_top();

      // if (localStorage.tokenData) { // если в sessionStorage присутствует tokenData, то берем её
      //   console.log('ok')
      // } else {
      //   return window.location.replace('/login.html'); // если токен отсутствует, то перенаправляем пользователя на страницу авторизации
      // }
    },
    methods: {
      check_all(value) {
        this.domains.map(e => e.checked = value);
      },
      sidebar_toggle(data) {
        if (data.open) this.sidebar_status = true;
        else this.sidebar_status = !this.sidebar_status;
        if (data.sidebar_type === 'domain') {
          this.sidebar_type = 'domain';
          try {
            if (!data.domain) return;
            this.domains.forEach(d => {
              if (d.domain === data.domain) {
                this.domain_info.domain = d.domain;
                this.domain_info.accountable_id = d.accountable_id;
                this.domain_info.accountable_name = d.accountable_name;
                this.domain_info.metrika = d.metrika;
                this.domain_info.status = d.status;
                this.domain_info.name = d.name;
                this.domain_info.id = d.id;
                this.domain_info.emails = d.emails ? d.emails : [];
              }
            });
          } catch (e) {
            console.log(e);
          }
        } else if (data.sidebar_type === 'managers') {
          this.sidebar_type = 'managers';
        }
      },
      async set_domain_list(response) {
        if (response.domains) {
          let data_domains_timeles = [];
          for (let domain in response.domains) {
            let domain_info = {
              checked: false
            };
            for (let key in response.domains[domain]) {
              key.indexOf('dealer_name') === 0 ?
                domain_info.name = response.domains[domain][key] :
                '';
              key.indexOf('status') === 0 ?
                domain_info.status = response.domains[domain][key] :
                '';
              key.indexOf('metrics_id') === 0 ?
                domain_info.metrika = response.domains[domain][key] :
                '';
              key.indexOf('accountable_id') === 0 ?
                domain_info.accountable_id = response.domains[domain][key] :
                '';
              key.indexOf('accountable_name') === 0 ?
                domain_info.accountable_name = response.domains[domain][key] :
                '';
              key.indexOf('id') === 0 ?
                domain_info.id = response.domains[domain][key] :
                '';
              key.indexOf('domain_name') === 0 ?
                domain_info.domain = response.domains[domain][key] :
                '';
              key.indexOf('brand_name') === 0 ?
                domain_info.brand_name = response.domains[domain][key] :
                '';
              key.indexOf('emails') === 0 ?
                domain_info.emails = response.domains[domain][key] :
                '';
            }
            data_domains_timeles.push(domain_info);
          }
          this.domains = data_domains_timeles;
          setTimeout(() => {
            this.preloader_status = false;
          }, 500);
        } else {
          console.log('не удалось выгрузить список доменов')
        }
        if (response.brands) {
          for (let count in response.brands) {
            if (response.brands[count] !== '') {
              this.brands.push({
                id: response.brands[count],
                name: response.brands[count]
              });
            }
          }
        }
      },
      set_managers(response) {
        this.managers = [];
        for (let manager in response) {
          this.managers.push({
            id: response[manager].id,
            name: response[manager].full_name
          });
        }
      },
      change_sidebar_manager(manager_id) {
        this.domain_info.accountable_id = manager_id;
      },
      new_manager_edit(data) {
        this.new_manager = data.new_manager;
      },
      change_domain_manager(data) {
        this.domain_info.accountable_id = data;
      },
      changes_date(date) {
        this.report_data.date = date.sort()
      },
      change_filter(filters) {
        if (filters) {
          for (let filter in filters) {
            if (filters[filter].length > 1) {
              this.filters_data[filter] = filters[filter][0];
              for (let i = 1; i < filters[filter].length; i++) {
                this.filters_data[filter] += ',' + filters[filter][i];
              }
            } else if (filters[filter].length === 1) {
              this.filters_data[filter] = filters[filter][0];
            } else {
              delete this.filters_data[filter];
            }
          }
        }
        this.filter_domains(this.filters_data, this.sorting_data);
      },
      filter_sortable(sortable_element) {
        for (let sort in this.sorting_data) {
          if (sort === sortable_element) {
            if (this.sorting_data[sortable_element]) {
              this.sorting_data[sort] === 'DESC' ?
                this.sorting_data[sort] = false :
                this.sorting_data[sort] = 'DESC';
            } else {
              this.sorting_data[sort] = 'ASC';
            }
          }
        }
        this.filter_domains(this.filters_data, this.sorting_data);
      },
      update_emails_sidebar(emails) {
        this.domain_info.emails = emails;
      },
      generate_report(response) {
        pdf_created(response);
      },
      async global_save() {
        this.domains.forEach(e => {
          if (e.checked) {
            this.get_report_data(e.domain)
          }
        });
      },

      // requests
      async dell_manager(data) {
        await axios({
          url: this.request_url + '/accauntable/' + data.id,
          headers: {
            "Authorization": this.auth_token
          },
          method: 'POST'
        })
          .then(r => r.data)
          .then(request => {
            let message_text;
            request.state === 'OK' ?
              message_text = `Пользователь ${data.name} успешно уничтожен!` :
              message_text = request.state;
            this.notification_message = {
              title: 'Внимание!',
              text: message_text
            };
            setTimeout(() => {
              this.show_notification = true;
              console.log(this.show_notification);
            }, 100);
          });
        await this.get_managers();
      },
      async get_managers() {
        await axios({
          method: 'GET',
          url: this.request_url + '/accauntable',
          headers: {
            "Authorization": this.auth_token
          },
        })
          .then(r => r.data)
          .then(response => {
            if (response.accountables) {
              this.set_managers(response.accountables);
            }
          });
      },
      async change_domain_info() {
        let form_data, link;
        if (this.sidebar_type === 'domain') {
          form_data = {
            dealer_name: this.domain_info.name,
            domain_name: this.domain_info.domain,
            metrics_id: this.domain_info.metrika,
            status: this.domain_info.status,
            accountable_id: this.domain_info.accountable_id,
            emails: this.domain_info.emails
          };
        }
        this.sidebar_status = false;
        setTimeout(() => this.show_notification = true, 100);
        if (this.domain_info.id) {
          link = '/update/' + this.domain_info.id;
          await axios({
            method: 'PUT',
            url: this.request_url + link,
            data: form_data,
            headers: {
              "Authorization": this.auth_token
            },
          })
            .then(r => {
              this.filter_domains(this.filters_data, this.sorting_data);
              this.notification_message = {
                title: 'Бамс!',
                text: 'Данные успешно обновлены!'
              };
            });
        } else {
          link = '/store';
          await axios({
            url: this.request_url + link,
            body: form_data,
            type: 'POST',
            headers: {
              "Authorization": this.auth_token
            },
          })
            .then(r => {
              this.filter_domains(this.filters_data, this.sorting_data);
            });
        }
      },
      async get_domains_list() {
        this.preloader_status = false;
        await axios({
          method: 'GET',
          url: this.request_url + '',
          headers: {
            "Authorization": this.auth_token
          }
        })
          .then(r => r.data)
          .then(data => this.set_domain_list(data));
      },
      async add_new_manager() {
        await axios({
          method: 'POST',
          data: {
            full_name: this.new_manager
          },
          url: this.request_url + '/accauntable',
          headers: {
            "Authorization": this.auth_token
          }
        })
          .then(r => r.data);
        await this.get_managers();
      },
      async get_report_data(domain_info) {
        let result = false;
        if (this.report_data.date === '') {
          setTimeout(() => this.show_notification = true, 100);
          this.notification_message = {
            title: 'Выберите дату',
            text: 'Что бы скачать отчёт, выберите диапазон дат.'
          };
        } else if (domain_info.id === '') {
          setTimeout(() => this.show_notification = true, 100);
          this.show_notification = true;
          this.notification_message = {
            title: 'Домен без id',
            text: 'У данного домена нету id. Его нужно перезаписать.'
          };
        } else {
          this.report_data.domain = domain_info.domain;
          this.report_data.counter_id = domain_info.id;
          this.progress_bar_on = true;
          await axios({
            method: 'POST',
            url: this.request_url + '/getdata',
            data: this.report_data,
            headers: {
              "Authorization": this.auth_token
            },
          })
            .then(r => r.data)
            .then(data => {
              setTimeout(() => {
                this.progress_bar_on = false;
              }, 500);
              this.domain_statistic = data;
              this.domain_statistic.domain = domain_info.domain;
              this.domain_statistic.domain_name = domain_info.domain_name;
              this.domain_statistic.move_type = domain_info.move_type;
              this.domain_statistic.emails = domain_info.emails;
              result = true;
            });
        }
        return result
      },
      async filter_domains(filters, sortable) {
        let utm_filter = '';
        for (let filter in filters) {       // собираем фильтры по ключам, которые заранее отформатировали
          utm_filter += 'filter' + `[${filter}]` + '=' + filters[filter] + '&';
        }
        for (let sort in sortable) {
          if (sortable[sort]) {
            utm_filter += 'sort=';
            break;
          }
        }
        for (let sort in sortable) {
          if (sortable[sort]) {
            sortable[sort] === 'ASC' ?
              utm_filter += sort + ',' :
              utm_filter += '-' + sort + ',';
          }
        }
        utm_filter = utm_filter.slice(0, utm_filter.length - 1); // убираем лишнюю запятую
        this.preloader_status = true;
        await axios({
          method: 'GET',
          url: this.request_url + '/filter?' + utm_filter,
          headers: {
            "Authorization": this.auth_token
          }
        })
          .then(r => r.data)
          .then(data => this.set_domain_list(data));
      },
    },
    watch: {
      domain_statistic: function() {
        this.generate_report(this.domain_statistic)
      }
    },
  };
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,900&display=swap');

  .reports {

    &__progress-bar {
      position: fixed;
      display: none;
      height: 8px !important;
      top: 0;
      left: 0;
      z-index: 3;

      &.show {
        display: block;
      }
    }

    &__list {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 50%;
        transform: translate(-50%, 200px);
        top: 0;
        height: calc(100% - 200px);
        min-height: 475px;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        max-width: 1150px;
        background: #32323573;
        transition: .2s ease-out;
        z-index: 11;
      }

      &.preloader_on::before {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  #app {
    background: linear-gradient(135deg, #3d3d3d, #040404);
    min-height: 100vh;

    font-family: 'Montserrat', sans-serif;
  }

  @media (min-width: 960px) {
    .container {
      max-width: 100% !important
    }
  }

  .wrapper {
    position: relative;

    &::before {
      position: fixed;
      background-color: #ffffff22;
    }

    &__preloader {
      opacity: 0;
      transition: .2s ease-in-out;
      pointer-events: none;

      &.show {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .v-messages {
    display: none !important;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-input {
    padding: 0;
  }

  #scroll-top-btn {
    position: fixed;
    display: none;
    right: 100px;
    bottom: 100px;
    width: 35px;
    height: 35px;
    padding: 0;
    animation: scrollShow 0.52s ease-in-out;
    box-shadow: 0 0 5px hsl(0deg 100% 98% / 56%);
    z-index: 10;

    svg {
      width: 13px;
    }
  }

  .primary--text {
    color: $clr_one !important
  }

  @keyframes scrollShow {
    0% {
      transform: translateY(150px) scaleX(0);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
