<template>
  <div class="preloader">
    <img :src="preloader_src" />
  </div>
</template>

<script>
  import preloader from '../assets/images/preloader.svg';

  export default {
    name: 'Preloader',
    data() {
      return {
        preloader_src: preloader,
      };
    }
  };
</script>

<style lang="scss" scoped>
  .preloader {
    position: sticky;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    width: 100%;
    max-width: 1150px;
    height: 100%;
    top: 0;
    margin: -210px auto 0 auto;
    transform: translateY(250px);
    z-index: 11;
  }
</style>
