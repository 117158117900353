<template>
  <div class="managers">
    <div
      class="managers__element"
      v-for="manager in managers"
      :key="manager.id"
      data-id="manager.id"
    >
      {{ manager.name }}
      <span class="managers__element-id">
        id: {{ manager.id }}
      </span>
      <v-btn
        x-small
        @click="$parent.$emit('dell_manager', {
          id: manager.id,
          name: manager.name
        })"
        class="managers__element-del">
        удалить
      </v-btn>
    </div>
    <v-text-field
      class="managers__add-manager"
      label="Новый менеджер"
      v-model="new_manager"
      @focusout="$parent.$emit('new_manager_edit', {
        new_manager
      })"
    />
    <v-btn
      @click="() => {
        $parent.$emit('add_new_manager');
        this.new_manager = ''
      }"
    >
      Добавить менеджера
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'ManagersSidebar',
    props: {
      managers: {
        type: Array,
        required: false
      },
    },
    data(){
      return {
        new_manager: ''
      };
    }
  };
</script>

<style lang="scss" scoped>
  .managers {
    display: flex;
    flex-direction: column;
    grid-gap: 5px 0;

    &__element {
      display: flex;
      padding: 5px;
      grid-gap: 5px 10px;
      color: #fff;
      align-items: center;
      background: linear-gradient(to left, #797979, #00000022);
      border-radius: 4px;
    }

    &__element-id {
      font-size: 12px;
      font-weight: 500;
      color: $clr_one;
    }

    &__element-del {
      margin-left: auto;
      margin-right: 5px;
    }

    &__add-manager {
      margin-top: 25px;
    }
  }
</style>
