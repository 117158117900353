<template>
  <div class="header">
    <div class="header__logotype">
<!--      <img :src="logo_src"-->
<!--           alt="logo"-->
<!--           height="106px"-->
<!--      >-->
      <div class="header__self-logo">
        Reporting <span>service</span>
      </div>
    </div>
    <div class="header__selects">
      <div class="header__select-brand">
        <v-select
          v-model="selected_brands"
          dark
          multiple
          outlined
          label="Бренды"
          :items="brands"
          item-text="name"
          item-value="id"
          @change="$emit('change_filter', {
            brand_name: selected_brands
          })"
        />
      </div>
      <div
        v-click-outside="hide"
        class="header__date-pick"
      >
        <v-btn
          dark
          class="header__date-toggle"
          @click="toggle_date_picker = !toggle_date_picker"
        >
          {{ getFormat(dates) }}
        </v-btn>
        <v-date-picker
          v-show="toggle_date_picker"
          v-model="dates"
          dark
          range
          class="header__date"
          @change="$emit('change_date', dates)"
        />
      </div>
      <div class="header__select-people">
        <v-select
          v-model="selected_people"
          dark
          multiple
          outlined
          label="Менеджеры"
          :items="managers"
          item-text="name"
          item-value="id"
          @change="$emit('change_filter', {
            accountable_id: selected_people
          })"
        />
      </div>
      <v-btn
        class="header__managers-list"
        x-small
        @click="$emit('toggle_sidebar', {
          open: true,
          sidebar_type: 'managers'
        })"
      >
        Список менеджеров
      </v-btn>
    </div>
  </div>
</template>

<script>
  import logo from '../assets/images/logotype.png';

  export default {
    name: 'Header',
    props: {
      managers: {
        type: Array,
        required: true
      },
      brands: {
        type: Array,
        required: true
      },
    },
    data() {
      return {
        dates: [this.localeDate(), this.localeDate()],
        toggle_date_picker: false,
        selected_brands: '',
        selected_people: '',
        logo_src: logo
      };
    },
    methods: {
      getFormat(date) {
        return 'c ' + date.join(' по ');
      },
      getFormatSelects(arr) {
        if (arr.length > 2) {
          return arr.slice(0, 2);
        } else return arr;
      },
      hide() {
        this.toggle_date_picker = false;
      },
      localeDate() {
        let date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        // date = date.replace(/\./g,'-');
        // date = date.slice(0, 6) + date.slice(8, 10);
        return date;
      }
    },
  };
</script>
<style lang="scss">
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 11;

    &__selects {
      display: flex;
      position: relative;
      margin-top: 65px
    }

    &__logotype {
      display: flex;
      align-items: center;
      height: 108px;
    }

    &__self-logo {
      color: #ececec;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 35px;
      cursor: default;

      span {
        padding: 5px 20px;
        background: linear-gradient(
            170deg, $clr_one, $clr_two, $clr_three 100px);
        border-radius: 45px;
        border-bottom-left-radius: 32px;
        border-top-right-radius: 5px;
        box-shadow: 4px 4px 12px #000000, -4px -4px 12px #373535;
        color: #ececec;
        font-style: normal;
      }
    }

    &__date-pick {
      box-shadow: 4px 4px 16px #000000de, -4px -4px 16px #e5e5e51c;
    }

    &__managers-list {
      position: absolute !important;
      right: 0;
      bottom: -32px;
      background-color: $clr_three !important;
    }

    &__select-brand {
      height: max-content;
      margin-right: 35px;

      border-radius: 4px;
      background: $clr_one;
      box-shadow: 4px 4px 16px #000000de, -4px -4px 16px #e5e5e51c;
    }

    &__select-people {
      height: max-content;
      margin-left: 35px;

      border-radius: 4px;
      background: $clr_one;
      box-shadow: 4px 4px 16px #000000de, -4px -4px 16px #e5e5e51c;
    }

    &__date-toggle {
      height: 50px !important;
      width: 270px;
      font-size: 14px !important;

      background: $clr_two !important;
      border-radius: 4px !important;
    }

    .v-picker__title {
      display: none;
    }

    .v-picker {
      position: fixed;

      &.show {
        display: block;
      }
    }

    .v-input {
      width: 310px;
    }

  }

  .v-btn.v-btn--active.v-btn--rounded {
    background-color: $clr_one !important;
  }

  .v-application--is-ltr .v-text-field .v-label {
    color: #ececec;
  }


  .v-text-field__details {
    display: none !important;
  }

  .v-input__slot {
    margin: 0 !important;
    min-height: 50px !important;

    fieldset {
      border: none !important;
    }
  }

  .v-input__append-inner {
    margin-top: 14px !important;
  }

  .v-label {
    top: 32% !important;
  }

  .v-text-field--outlined .v-label--active {
    transform: translateY(-45px) scale(1.25) !important;
  }

  .v-select__selections {
    position: relative;
  }

  .v-select__selections .v-select__selection:nth-child(n+2) {
    display: none !important;
  }
</style>
